import styled from '@emotion/styled'

export const LineBreak = styled.br<{
  downmd?: number
  upmd?: number
}>`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: ${({ downmd }) => (downmd ? 'block' : 'none')};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: ${({ upmd }) => (upmd ? 'block' : 'none')};
  }
`
