import { useTranslation } from 'next-i18next'
import React from 'react'
import { LineBreak } from 'src/components/LineBreak'
import StepsSection from 'src/components/StepsSection'
import { RedirectUrls } from 'src/types/types'

const Steps = () => {
  const { t } = useTranslation('landing')
  return (
    <StepsSection
      title={t('INDEX_STEPS.HEADING')}
      subtitle={
        <>
          {t('INDEX_STEPS.SUBHEADING_FR_1')}
          <LineBreak downmd={1} />
          {t('INDEX_STEPS.SUBHEADING_FR_2')}
        </>
      }
      steps={t('INDEX_STEPS.STEPS', { returnObjects: true })}
      ctaText={t('INDEX_STEPS.BUTTON_SEARCH')}
      ctaHref={RedirectUrls.Search}
    />
  )
}

export default React.memo(Steps)
