import React from 'react'
import { Vsb } from 'src/components/Hidden'
import BaseButton from '../Buttons/BaseButton'
import Carousel from '../Carousel/Carousel'
import Step, { ICard } from './Step'

type IProps = {
  title: string | React.ReactNode
  subtitle: string | React.ReactNode
  steps: ICard[]
  ctaHref?: string
  ctaClick?: () => void
  ctaText: string
  cardBgColor?: string
  ctaButtonWidth?: number
}

const Steps = ({
  title,
  subtitle,
  steps,
  ctaText,
  ctaHref,
  ctaClick,
  cardBgColor,
  ctaButtonWidth
}: IProps) => {
  return (
    <div className="inset-x-0 py-[90px] shadow-none">
      <div className="mx-5 flex flex-col items-center pb-[20px] md:pb-[40px]">
        <h2 className="text-center text-[29px] font-semibold leading-9 text-black-400 md:text-[32px] md:leading-[42px]">
          {title}
        </h2>
        <p className="mb-[10px] text-center text-base font-normal leading-5 text-black-400">
          {subtitle}
        </p>
      </div>

      <div className="hidden w-full justify-center px-5 md:flex lg:px-7 xl:px-12">
        <div className="grid w-full max-w-[1832px] grid-cols-3 gap-8">
          {steps.map((card) => (
            <Step card={card} key={card.title} cardBgColor={cardBgColor} />
          ))}
        </div>
      </div>

      <Vsb xs sm>
        <Carousel
          items={steps}
          carouselOptions={{
            align: 'center',
            slidesToScroll: 1,
            containScroll: 'keepSnaps',
            loop: true
          }}
        >
          {({ item }) => <Step card={item} cardBgColor={cardBgColor} />}
        </Carousel>
      </Vsb>

      <div className="flex w-full flex-col flex-wrap items-center justify-center gap-[23px] pt-11">
        <BaseButton
          fontSize={14}
          width={ctaButtonWidth ?? 240}
          size="medium"
          color="primary"
          variant="contained"
          rounded
          href={ctaHref}
          onClick={ctaClick}
        >
          {ctaText}
        </BaseButton>
      </div>
    </div>
  )
}

export default React.memo(Steps)
