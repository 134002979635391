import styled from '@emotion/styled'

/**
 * Viewable breakpoints
 */
export const Vsb = styled.div<{
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
}>`
  display: none;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: ${({ xs }) => (xs ? 'block' : 'none')};
  }
  ${({ theme }) => theme.breakpoints.between('428', '900')} {
    display: ${({ sm }) => (sm ? 'block' : 'none')};
  }
  ${({ theme }) => theme.breakpoints.between('900', '1024')} {
    display: ${({ md }) => (md ? 'block' : 'none')};
  }
  ${({ theme }) => theme.breakpoints.between('1024', '1280')} {
    display: ${({ lg }) => (lg ? 'block' : 'none')};
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: ${({ xl }) => (xl ? 'block' : 'none')};
  }
`
